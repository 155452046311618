<template>
  <div class="home">
    <div>Hi!</div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
